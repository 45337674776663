<template>
   <div class="container h-100">

    

      <div class="h-75 pt-5">

        <div class="h-100" v-if="this.rldata.screen_flags['child_create_flag'] == false && this.rldata.screen_flags['child_connect_flag'] == false">
            <div class="d-flex justify-content-start align-items-center">
                <p v-if="this.rldata.screen_flags['allow_back_button']" style="cursor:pointer; font-size:22px;"><i v-on:click="$router.go(-1)" class="mdi mdi-arrow-left"></i></p>
                <p v-else style="cursor:pointer; font-size:22px;"><i class='mdi mdi-exit-to-app' v-on:click="$root.logout()"> Exit</i></p>
            </div>
        
            <div class="d-flex justify-content-center align-items-center h-100 flex-column">
            


                <p style="font-weight: 500; font-size: 22px;">Does your child already have an account?</p>

                <div class="d-flex mt-4 w-75 justify-content-around">

                    <div class="d-flex me-5">
                    <div>
                        <div class="d-flex flex-column h-100 justify-content-between align-items-center">
                            <p style="font-weight: 500; font-size: 20px;">My child needs an account</p>
                            <img src="/resource/img/child_connect_new.svg" alt="child_connect_new" style="width: 300px; height:168px;">
                            <button class="btn w-100 mt-3" style="background: #55D18E; box-shadow: 0px 4px 0px #4BBD80; border-radius: 12px; color: white;" @click="rldata.screen_flags['child_create_flag'] = true;">Create</button>
                            <!-- padding: 12px 24px;  -->
                        </div>
                    </div>
                    </div>

                    <div class="d-flex">
                    <div>
                        <div class="d-flex flex-column h-100 justify-content-between align-items-center">
                            <p style="font-weight: 500; font-size: 20px;">My child already has an account</p>
                            <img src="/resource/img/child_connect_exist.svg" alt="child_connect_exist" style="width: 300px; height:168px;">
                            <!-- padding: 12px 24px; -->
                            <button class="btn w-100 mt-3" style="background: #8C90FF; box-shadow: 0px 4px 0px #5E62C7; border-radius: 12px; color: white;" @click="rldata.screen_flags['child_connect_flag'] = true;">Connect</button>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>


        <div class="h-100" v-if="this.rldata.screen_flags['child_create_flag']">

            <div class="d-flex justify-content-start align-items-center">
                <p style="cursor:pointer; font-size:22px;"><i @click="rldata.screen_flags['child_create_flag'] = false;" class="mdi mdi-arrow-left"></i></p>
            </div>

            <div class="h-100">
                <div class="d-flex justify-content-center align-items-center h-100">
                <div>
                    <div>
                        <p style="font-weight: 500; font-size: 22px;">Please fill in the form below</p>
                
                        <div>
                            <form @submit.prevent="create_new_child">
                                <div class="form-group mb-3">
                                    <input type="text" placeholder="Child's first name" class="form-control" id="inputChildFirstName" required v-model="rldata.new_child.child_first_name" style="border: 2px solid #DCE0EA; border-radius: 12px;">
                                </div>
                                <div class="form-group mb-3">
                                    <input type="text" placeholder="First letter of child's last name" class="form-control" id="inputChildInitial" required v-model="rldata.new_child.child_initial" style="border: 2px solid #DCE0EA; border-radius: 12px;">
                                </div>
                                <div class="form-group mb-3 text-end">
                                    <div class="input-group" id="show_hide_password">
                                        <input class="form-control" placeholder="Password" type="password" required v-model="rldata.new_child.child_password" style="border: 2px solid #DCE0EA; border-radius: 12px 0px 0px 12px;">
                                        <div class="input-group-append">
                                            <span class="input-group-text h-100"><a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a></span>
                                        </div>
                                    </div>
                                    <small id="passwordHelp" class="form-text text-muted">*must contain letters and at least one number</small>
                                </div>
                                <div class="input-group mb-3">
                                    <select class="custom-select w-100" required v-model="rldata.new_child.child_grade" style="border: 2px solid #DCE0EA; border-radius: 12px; padding: 12px 16px;">
                                        <option value="1">Grade 1</option>
                                        <option value="2">Grade 2</option>
                                        <option value="3">Grade 3</option>
                                    </select>
                                </div>
                                <button type="submit" class="btn w-100" style="background: #55D18E; box-shadow: 0px 4px 0px #4BBD80; border-radius: 12px; color: white;">Create an account</button>
                            </form>
                        </div>

                    </div>
                </div>
                </div>
            </div>
        </div>


        <div class="h-100" v-if="this.rldata.screen_flags['child_connect_flag']">

            <div class="d-flex justify-content-start align-items-center">
                <p style="cursor:pointer; font-size:22px;"><i @click="rldata.screen_flags['child_connect_flag'] = false;" class="mdi mdi-arrow-left"></i></p>
            </div>

            <div class="h-100">
                <div class="d-flex justify-content-center align-items-center h-100">
                    <div>
                        <div>
                            
                            <div class="d-flex flex-column align-items-center">
                                <p style="font-weight: 500; font-size: 22px; text-align:center;">Please enter your child’s information</p>

                                <form @submit.prevent="connect_child" class="w-75">
                                    <div class="form-group mb-3">
                                        <input type="text" placeholder="Username" class="form-control" id="inputChildUsername" required v-model="rldata.child_connect_data.username" style="border: 2px solid #DCE0EA; border-radius: 12px;">
                                    </div>
                                    <div class="form-group mb-3">
                                        <div class="input-group" id="show_hide_password">
                                            <input class="form-control" placeholder="Password" type="password" required v-model="rldata.child_connect_data.password" style="border: 2px solid #DCE0EA; border-radius: 12px 0px 0px 12px;">
                                            <div class="input-group-append">
                                                <span class="input-group-text h-100"><a href=""><i class="fa fa-eye-slash" aria-hidden="true"></i></a></span>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" class="btn w-100" style="background: #8C90FF; box-shadow: 0px 4px 0px #5E62C7; border-radius: 12px; color: white;">Add child</button>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


      </div>
   </div>
</template>

<script>
// import axios from "axios"
// import router from "@/router"
var rldata = {
    user: {
        _id: ''
    },

    screen_flags: {
        child_create_flag: false, 
        child_connect_flag: false,
        allow_back_button: false,
    },

    new_child: {
        child_first_name: '',
        child_initial: '',
        child_password: '',
        child_grade: '1'
    },

    child_connect_data: {
        username: '',
        password: ''
    }
}
export default {
  name: "Child_Connect",
  data () {
    return {
      rldata
    }
  },
  methods: {
    connect_child: function() {
        let data = {
            adult_id: this.rldata.user._id,
            username: this.rldata.child_connect_data.username,
            password: this.rldata.child_connect_data.password
        }
        
        this.$root.child_connect(data);
    },
    create_new_child: function() {
        if(this.rldata.new_child.child_first_name.length > 0 && this.rldata.new_child.child_initial.length == 1 && this.rldata.new_child.child_password.length > 0) {

            let username = this.rldata.new_child.child_first_name + this.rldata.new_child.child_initial;
            username = username.replace(/\s+/g, '');
            username = username.replace(/\'+/g, '');
            username = username.replace(/-+/g, '');
            username = username.toLowerCase();


            let data = {
                adult_id: this.rldata.user._id,
                first_name: this.rldata.new_child.child_first_name,
                initial: this.rldata.new_child.child_initial,
                grade: this.rldata.new_child.child_grade,
                password: this.rldata.new_child.child_password,
                adult_connected: true,
                username
            };
            // console.log(data);
            this.$root.add_new_child(data);
        } else {
            alert('Please, fill all fields');
        }
    }
  },
  mounted () {
      try{
        this.$root.getUserData(this.rldata);
      } catch(err){
        this.$root.logout();
      }
  },
    beforeMount() {
      var cleanData = {
         user: {
            _id: ''
         },

         screen_flags: {
            child_create_flag: false, 
            child_connect_flag: false,
            allow_back_button: false,
         },

         new_child: {
            child_first_name: '',
            child_initial: '',
            child_password: '',
            child_grade: '1'
         },

        child_connect_data: {
            username: '',
            password: ''
        }
      };
      this.rldata = cleanData;
   },
   watch: {
      "rldata.user": function(handler){
        if(handler != null) {
          if(this.rldata.user.childs.length > 0){
            this.rldata.screen_flags.allow_back_button = true;
          }
        }
      },
     "rldata.screen_flags.child_create_flag": function(handler){
       if(handler == true) {
            $(document).ready(function() {
               $("#show_hide_password a").on('click', function(event) {
                     event.preventDefault();
                     if($('#show_hide_password input').attr("type") == "text"){
                        $('#show_hide_password input').attr('type', 'password');
                        $('#show_hide_password i').addClass( "fa-eye-slash" );
                        $('#show_hide_password i').removeClass( "fa-eye" );
                     }else if($('#show_hide_password input').attr("type") == "password"){
                        $('#show_hide_password input').attr('type', 'text');
                        $('#show_hide_password i').removeClass( "fa-eye-slash" );
                        $('#show_hide_password i').addClass( "fa-eye" );
                     }
               });
            });
       }
     },
     "rldata.screen_flags.child_connect_flag": function(handler){
       if(handler == true) {
            $(document).ready(function() {
               $("#show_hide_password a").on('click', function(event) {
                     event.preventDefault();
                     if($('#show_hide_password input').attr("type") == "text"){
                        $('#show_hide_password input').attr('type', 'password');
                        $('#show_hide_password i').addClass( "fa-eye-slash" );
                        $('#show_hide_password i').removeClass( "fa-eye" );
                     }else if($('#show_hide_password input').attr("type") == "password"){
                        $('#show_hide_password input').attr('type', 'text');
                        $('#show_hide_password i').removeClass( "fa-eye-slash" );
                        $('#show_hide_password i').addClass( "fa-eye" );
                     }
               });
            });
       }
     }
   }
}
</script>